import React from "react"
import Layout from "../components/Layout"

const Gracias = () => (
  <Layout>
    <h1 className="text-center mt-5">Contacto</h1>
    <main className="grid grid-cols-5 justify-center auto-rows-max gap-4 md:gap-8 px-4">
    <div className="col-start-2 col-end-5">
    <p className="text-center">Gracias por contactarnos!</p>
    </div>
    
    </main>
  </Layout>
)

export default Gracias